import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./loader-slice";
import clientsReducer from "./clients-slice";
import environmentReducer from "./environment-slice";
import loginReducer from "./login-slice";

export default configureStore({
  reducer: {
    loaderReducer,
    loginReducer,
    clientsReducer,
    environmentReducer,
  },
});

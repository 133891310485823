import React from "react";
import "./rounded-button.scss";

export default function RoundedButton(props) {
  return (
    <button
      className={
        "rounded-button" +
        (props.primary ? " primary" : "") +
        (props.disabled ? " disabled" : "")
      }
      {...props}
      disabled={props.disabled}
    >
      <i className={props.icon} style={props.iconstyle} />
    </button>
  );
}

import React from "react";
import "./box.scss";

export default function Box(props) {
  return (
    <div className="box" style={{ width: props.width || "300px" }}>
      {props.children}
    </div>
  );
}

import i18n from "i18next";
import { initReactI18next as reactI18nextModule } from "react-i18next";
//import intervalPlural from "i18next-intervalplural-postprocessor";
import * as en from "./locales/en";
// import * as he from "./locales/en";
// import * as es from "./locales/es";
// import * as ar from "./locales/ar";
// import * as de from "./locales/de";
// import * as it from "./locales/it";
// import * as fr from "./locales/fr";
// import * as pl from "./locales/pl";
// import * as pt_br from "./locales/pt-BR";
// the translations
const resources = {
  en,
  //   he,
  //   ar,
  //   es,
  // it,
  // fr,
  // de,
  // pt_br,
  // pl,
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  //.use(intervalPlural)
  .init({
    lng: "en",
    debug: false,
    resources,
    fallbackLng: "en",
    defaultNS: "Common",
    fallbackNS: "Common",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

import React from "react";
import Modal from "../../../../../../Components/Modal/modal";
import Button from "../../../../../../Components/Button/button";

import { useDispatch } from "react-redux";
import { UpdateActiveClientUser } from "../../../../../../Redux/clients-slice";
import { getClient } from "../../../../../../Redux/clients-slice";

export const SetActiveModal = (props) => {
  const dispatch = useDispatch();

  return (
    <Modal
      title={(props.activeStatus ? "Delete" : "Activate") + " User"}
      {...props}
    >
      <p>
        Are you sure that you want to{" "}
        {props.activeStatus ? "delete" : "activate"} the user?
      </p>
      <br />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            UpdateActiveClientUser({
              UserId: props.userId,
              Active: !props.activeStatus,
            })
          ).then(() => {
            dispatch(getClient(props.ClientId));
            props.close();
          });
        }}
      >
        <Button submit>Confirm</Button>
      </form>
    </Modal>
  );
};

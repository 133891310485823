import React from "react";
import "./input.scss";

export default function Input(props) {
  return (
    <input
      {...props}
      className={"input " + props.className}
      value={props.value || ""}
      required={props.required}
    />
  );
}

import React, { useState } from "react";
import "./change-password.scss";

// Functions
import { useDispatch } from "react-redux";
import { cangePasswordAction } from "../../../Redux/login-slice";
import { useTranslation } from "react-i18next";

// Components
import Input from "../../../Components/Input/input";
import Button from "../../../Components/Button/button";
import { Link } from "react-router-dom";

export default function ChangePassword(props) {
  const dispatch = useDispatch();
  const [sent, setSent] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // i18next
  const { t, i18n } = useTranslation("Errors");

  return (
    <div className="login">
      <div className="login-form">
        <h1 style={{ margin: "0px" }}>Choose new password</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const validPass = new RegExp(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
            );
            if (password.trim() === "" || confirmPassword.trim() === "") {
              alert("Fill all fields");
            } else if (!validPass.test(password)) {
              alert("Password is invalid");
            } else if (password !== confirmPassword) {
              alert("Password doesn't match");
            } else {
              const data = {
                UserId: props.match.params.UserId,
                Token: props.match.params.Token,
                NewPassword: password,
              };
              dispatch(cangePasswordAction(data)).then((res) => {
                setSent(true);
                setTimeout(() => {
                  props.history.push("/");
                }, 5000);
              });
            }
          }}
        >
          {sent ? (
            <span>Password successfully changed</span>
          ) : (
            <>
              <span style={{ color: "red", fontSize: "12pt" }}>
                The password must contain at least 8 characters, including one
                capital letter, one small letter and one digit
              </span>
              <Input
                required
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                required
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button submit>Set Password</Button>
              <Link className="forgot-password-text text-small" to="/">
                Back to Login
              </Link>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

import { Route, Switch, Router, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./Components/Loader/loader";
import PageWrapper from "./Components/PageWrapper/page-wrapper";
import Login from "./Pages/Login/login";
import SignOut from "./Pages/SignOut/signout";
import AdminHomePage from "./Pages/Admin/AdminHomePage/admin-home-page";
import ClientHomePage from "./Pages/Client/ClientHomePage/client-home-page";
import Page404 from "./Pages/404/page404";
import ManageClients from "./Pages/Admin/ManageClients/manage-clients";
import ManageEnvironments from "./Pages/Admin/ManageEnvironments/manage-environments";
import ClientProfile from "./Pages/Admin/ManageClients/ClientProfile/client-profile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { CheckLogin } from "./Redux/login-slice";
import history from "./Utils/history";
import { ReCaptchaSiteKey } from "./Utils/const";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import React from "react";
import { withTranslation } from "react-i18next";
import ForgotPassword from "./Pages/Login/ForgotPassword/forgot-password";
import ChangePassword from "./Pages/Login/ChangePassword/change-password";
import { hideLoader, showLoader } from "./Redux/loader-slice";

function ProtectedRoute({ component: Component, adminRoute, e404, ...props }) {
  const isAdmin = useSelector((state) => state.loginReducer.isAdmin);
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  if (adminRoute && isAdmin != true) return <Redirect to="/client/home" />;
  return (
    <Route
      {...props}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : e404 ? (
          <Redirect to={{ path: "/login" }} />
        ) : (
          <Redirect to={{ path: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
}
function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const isINI = useSelector((state) => state.loginReducer.ini);

  const isAdmin = useSelector((state) => state.loginReducer.isAdmin);
  useEffect(() => {
    dispatch(showLoader());

    dispatch(CheckLogin()).finally(() => {
      dispatch(hideLoader());
    });
  }, []);
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={ReCaptchaSiteKey}
      useRecaptchaNet={true}
    >
      <div>
        <Loader />
        {isINI && (
          <Router history={history}>
            {/*<HashRouter>*/}
            <Switch>
              <Route
                exact
                path={["/", "/login"]}
                render={(props) =>
                  !isLoggedIn ? (
                    <Login {...props} />
                  ) : isAdmin ? (
                    <Redirect to="/admin/home" />
                  ) : (
                    <Redirect to="/client/home" />
                  )
                }
                // component={Login}
              />
              <Route path="/password/forgot" component={ForgotPassword} />
              <Route
                path="/password/change/:UserId/:Token"
                component={ChangePassword}
              />
              <ProtectedRoute exact path="/signout" component={SignOut} />

              <PageWrapper>
                {/* Admin Routes */}
                <Switch>
                  <ProtectedRoute path="/404" e404 component={Page404} />
                  <ProtectedRoute
                    exact
                    adminRoute
                    path="/admin/home"
                    component={AdminHomePage}
                  />
                  <ProtectedRoute
                    exact
                    adminRoute
                    path="/admin/manage-clients"
                    component={ManageClients}
                  />
                  <ProtectedRoute
                    exact
                    adminRoute
                    path="/admin/manage-clients/:id"
                    component={ClientProfile}
                  />
                  <ProtectedRoute
                    adminRoute
                    path="/admin/manage-environments"
                    component={ManageEnvironments}
                  />

                  {/* Client Routes */}
                  <ProtectedRoute
                    path="/client/home"
                    component={ClientHomePage}
                  />
                  <ProtectedRoute
                    path="/client/profile"
                    component={ClientProfile}
                  />
                  <Route path="*">
                    <Redirect to="/404" />
                  </Route>
                </Switch>
              </PageWrapper>
            </Switch>
          </Router>
        )}
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default withTranslation("Common")(App);

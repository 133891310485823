import React from "react";
import "./sidebar.scss";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const isAdmin = useSelector((state) => state.loginReducer.isAdmin);

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <h1>HR+</h1>
        <ul>
          <li>
            <NavLink
              activeClassName="is-active"
              to={isAdmin ? "/admin/home" : "/client/home"}
            >
              Home Page
            </NavLink>
          </li>

          {isAdmin ? (
            <li>
              <NavLink activeClassName="is-active" to="/admin/manage-clients">
                Clients
              </NavLink>
            </li>
          ) : (
            <li>
              <NavLink activeClassName="is-active" to="/client/profile">
                Client Profile
              </NavLink>
            </li>
          )}

          {/* <li>
            <NavLink
              activeClassName="is-active"
              to={
                isAdmin
                  ? "/admin/manage-environments"
                  : "/client/manage-environments"
              }
            >
              Environments
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/signout">SignOut</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

import React from "react";
import "./loader.scss";
import { useSelector } from "react-redux";

export default function Loader() {
  const isActive = useSelector((state) => state.loaderReducer.isActive);

  return isActive ? (
    <div className="loader">
      <div className="loader-bg" />
      <div className="loader-content">
        <i className="fas fa-cog fa-spin"></i>
      </div>
    </div>
  ) : null;
}

import React from "react";
import "./button.scss";

export default function Button({ submit, children, ...props }) {
  if (submit)
    return (
      <input type="submit" value={children} className="button" {...props} />
    );
  return (
    <button className="button" {...props}>
      {children}
    </button>
  );
}

import React, { useState } from "react";
import "./otp-modal.scss";

// Functions
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getLoginOtp, logIn } from "../../../Redux/login-slice";
import { hideLoader, showLoader } from "../../../Redux/loader-slice";

// Components
import Input from "../../../Components/Input/input";
import Modal from "../../../Components/Modal/modal";
import Button from "../../../Components/Button/button";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaV2SiteKey } from "../../../Utils/const";
export default function OTPModal(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const history = useHistory();
  const [reCaptchaV2Enabled, setreCaptchaV2Enabled] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [reCaptchaV2Token, setreCaptchaV2Token] = useState("");
  const onChangeRecaptchaV2 = (token) => {
    setreCaptchaV2Token(token);
  };
  const doOtp = async () => {
    let token;
    if (reCaptchaV2Enabled) token = reCaptchaV2Token;
    else token = await executeRecaptcha("SuperAdminLogin");
    dispatch(showLoader());

    dispatch(
      getLoginOtp({
        UserSMSCode: smsCode,
        reCaptchaToken: token,
        reCaptchaV2: reCaptchaV2Enabled,
      })
    ).then((res) => {
      dispatch(hideLoader());

      if (res.payload.Success) {
        history.push(props.from.from.pathname);
        /*

        // Redirect after login
        const isAdmin = res.payload.Payload.IsAdmin;

        if (isAdmin) {
          history.push("/admin/home");
        } else {
          history.push("/client/home");
        }
        */
      } else {
        if (res.payload.ErrorCode == "SuperAdmin.8220") {
          alert("Robot Detection Error");
          setreCaptchaV2Enabled(true);
        } else {
          alert("SMS code is incorrect");
        }
      }
    });
  };
  return (
    <Modal
      title="Code"
      close={() => {
        setSmsCode("");
        props.close();
      }}
      isOpen={props.isOpen}
      className="otp-modal"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          doOtp();
        }}
      >
        <Input
          required
          autoFocus
          type="number"
          placeholder="Code"
          onChange={(e) => setSmsCode(e.target.value)}
          value={smsCode}
        />
        {reCaptchaV2Enabled && (
          <>
            <br />
            <ReCAPTCHA
              className="recaptchaWraper"
              sitekey={ReCaptchaV2SiteKey}
              onChange={onChangeRecaptchaV2}
            />
          </>
        )}
        <Button submit>Submit</Button>
      </form>
      {/* <span className="code-not-received-text text-small">
        I did not receive a code
      </span> */}
    </Modal>
  );
}

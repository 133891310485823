import React from "react";
import RoundedButton from "../../../../Components/RoundedButton/rounded-button";
import "./create-client-box.scss";

export default function CreateClientBox(props) {
  return (
    <div className="create-client-box" onClick={props.onClick}>
      <RoundedButton primary="true" icon="fa fa-plus" />
    </div>
  );
}

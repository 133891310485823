import React from "react";
import "./manage-environments.scss";
import Title from "../../../Components/Title/title";

export default function ManageEnvironments() {
  return (
    <div className="manage-environments">
      <Title>Environments</Title>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../../Components/Title/title";
import { getClients } from "../../../Redux/clients-slice";
import { hideLoader, showLoader } from "../../../Redux/loader-slice";
import CreateClientBox from "./AddClientBox/create-client-box";
import ClientBox from "./ClientBox/client-box";
import CreateClientModal from "./CreateClientModal/create-client-modal";
import "./manage-clients.scss";
import { useTranslation } from "react-i18next";
import CheckBox from "../../../Components/CheckBox/checkbox";
import Select from "../../../Components/Select/select";

export default function ManageClients() {
  const dispatch = useDispatch();
  const clientsRedux = useSelector((state) => state.clientsReducer.clients);

  const [clients, setClients] = useState([]);
  const [clientsActivation, setClientsActivation] = useState(true);
  const [CreateClientModalOpen, setCreateClientModalOpen] = useState(false);
  const [selectedClientType, setSelectedClientType] = useState("2");

  // i18next
  const { t, i18n } = useTranslation("Errors");

  useEffect(() => {
    dispatch(showLoader());

    dispatch(getClients())
      .then((res) => {
        if (res.payload.Success) {
          dispatch(hideLoader());
          // setClients(res.payload.Payload);
          // changeClients();
        } else {
          dispatch(hideLoader());
          alert(t(res.payload.ErrorCode));
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    changeClients();
  }, [selectedClientType, clientsRedux]);

  const changeClients = () => {
    if (selectedClientType === "0") {
      if (clientsActivation) {
        setClients(
          clientsRedux.filter(
            (client) =>
              client.Active && (client.Type == "1" || client.Type == "2")
          )
        );
      } else {
        setClients(
          clientsRedux.filter(
            (client) => client.Type == "1" || client.Type == "2"
          )
        );
      }
    } else {
      if (clientsActivation) {
        setClients(
          clientsRedux.filter(
            (client) => client.Type == selectedClientType && client.Active
          )
        );
      } else {
        setClients(
          clientsRedux.filter((client) => client.Type == selectedClientType)
        );
      }
    }
  };

  useEffect(() => {
    // if (clientsActivation) {
    //   setClients(clients.filter((client) => client.Active));
    // } else {
    //   setClients(clientsRedux);
    // }
    changeClients();
  }, [clientsActivation]);

  return (
    <div className="manage-clients">
      <Title>Clients</Title>
      <br />
      <CheckBox
        checked={clientsActivation}
        onClick={() => {
          setClientsActivation((prevActivation) => !prevActivation);
        }}
      />
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          setClientsActivation((prevActivation) => !prevActivation);
        }}
      >
        Show only active
      </span>
      <Select
        placeholder="Filter client..."
        value={selectedClientType}
        style={{ float: "right", marginRight: "100px" }}
        onChange={(e) => setSelectedClientType(e.target.value)}
      >
        <optgroup label="Clients">
          <option value="0">All</option>
          <option value="1">Trial</option>
          <option value="2">Paying</option>
        </optgroup>
        <optgroup label="Other">
          <option value="3">Demo</option>
          <option value="4">Testing</option>
        </optgroup>
      </Select>
      <br /> <br />
      <div className="box-wrapper">
        <CreateClientBox onClick={() => setCreateClientModalOpen(true)} />

        {clients.map((client) => {
          return (
            <ClientBox
              key={client.Id}
              client={client}
              // clientName={client.Name}
              // clientId={client.Id}
              // isTrial={client.IsTrial}
            />
          );
        })}
      </div>
      <CreateClientModal
        isOpen={CreateClientModalOpen}
        close={() => setCreateClientModalOpen(false)}
      />
    </div>
  );
}

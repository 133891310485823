import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Utils/api";

export const createEnvironment = createAsyncThunk(
  "environments/createEnvironment",
  async (data) => {
    return Api.post("/Environment/CreateEnvironment", data).then(
      (res) => res.data
    );
  }
);

export const editEnvironment = createAsyncThunk(
  "environments/updateEnvironment",
  async (data) => {
    return Api.post("/Environment/UpdateEnvironment", data).then(
      (res) => res.data
    );
  }
);

export const removeEnvironment = createAsyncThunk(
  "environments/removeEnvironment",
  async (envId) => {
    return Api.post(`/Environment/RemoveEnvironment?Id=${envId}`).then(
      (res) => res.data
    );
  }
);

export const getEnvironmentAutoLoginLink = createAsyncThunk(
  "environments/getEnvironmentAutoLoginLink",
  async (data) => {
    return Api.get("/Environment/GetEnvironmentAutoLoginLink", {
      params: data,
    }).then((res) => res.data);
  }
);

export const toggleEnvironmentActivation = createAsyncThunk(
  "environments/toggleEnvironmentActivation",
  async (envId) => {
    return Api.post(
      `/Environment/ToggleEnvironmentActivation?Id=${envId}`
    ).then((res) => res.data);
  }
);

export const createModerator = createAsyncThunk(
  "environments/createModerator",
  async (data) => {
    return Api.post("/Environment/CreateModerator", data).then(
      (res) => res.data
    );
  }
);

export const getModerator = createAsyncThunk(
  "environments/getModerator",
  async (data) => {
    return Api.get("/Environment/GetModerator", {
      params: data,
    }).then((res) => res.data);
  }
);

export const updateModerator = createAsyncThunk(
  "environments/updateModerator",
  async (data) => {
    return Api.post("/Environment/UpdateModerator", data).then(
      (res) => res.data
    );
  }
);

export const sendFirstMessageToUser = createAsyncThunk(
  "environments/sendFirstMessageToUser",
  async (data) => {
    return Api.post("/Environment/SendFirstMessageToUser", data).then(
      (res) => res.data
    );
  }
);

export const sendChangePasswordMessageToUser = createAsyncThunk(
  "environments/sendChangePasswordMessageToUser",
  async (data) => {
    return Api.post("/Environment/SendChangePasswordMessageToUser", data).then(
      (res) => res.data
    );
  }
);

export const environmentSlice = createSlice({
  name: "environments",
  initialState: {
    autoLoginLink: "",
  },
  reducers: {},
  extraReducers: {
    [getEnvironmentAutoLoginLink.fulfilled]: (state, action) => {
      if (action.payload.Success === true)
        state.autoLoginLink = action.payload.Payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {} = environmentSlice.actions;

export default environmentSlice.reducer;

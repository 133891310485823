import React, { useEffect, useState } from "react";
import "./login.scss";

// Functions
import { useDispatch } from "react-redux";
import { getLogin } from "../../Redux/login-slice";
import { useTranslation } from "react-i18next";
import history from "../../Utils/history";

// Components
import Input from "../../Components/Input/input";
import Button from "../../Components/Button/button";
import OTPModal from "./OTPModal/otp-modal";
import { hideLoader, showLoader } from "../../Redux/loader-slice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaV2SiteKey } from "../../Utils/const";
import { Link, NavLink } from "react-router-dom";
export default function Login({ location }) {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // i18next
  const { t, i18n } = useTranslation("Errors");

  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [reCaptchaV2Enabled, setreCaptchaV2Enabled] = useState(false);
  const [reCaptchaV2Token, setreCaptchaV2Token] = useState("");
  const onChangeRecaptchaV2 = (token) => {
    setreCaptchaV2Token(token);
  };

  useEffect(() => {
    dispatch(hideLoader());
  }, []);
  const doLogin = async () => {
    let token;
    if (reCaptchaV2Enabled) token = reCaptchaV2Token;
    else token = await executeRecaptcha("SuperAdminLogin");
    dispatch(showLoader());

    dispatch(
      getLogin({
        Username: username,
        Password: password,
        reCaptchaToken: token,
        reCaptchaV2: reCaptchaV2Enabled,
      })
    ).then((res) => {
      dispatch(hideLoader());

      if (res.payload.Success) {
        setOtpModalOpen(true);
      } else {
        if (res.payload.ErrorCode == "SuperAdmin.8220") {
          alert("Robot Detection Error");
          setreCaptchaV2Enabled(true);
        } else {
          // alert(t(res.payload.ErrorCode));
          alert("Details are incorrect");
        }
      }
    });
  };
  return (
    <div className="login">
      <div className="login-form">
        <h1>Login</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            doLogin();
          }}
        >
          <Input
            required
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <Input
            required
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {reCaptchaV2Enabled && (
            <>
              <br />
              <ReCAPTCHA
                className="recaptchaWraper"
                sitekey={ReCaptchaV2SiteKey}
                onChange={onChangeRecaptchaV2}
              />
            </>
          )}
          <Button submit>Submit</Button>
        </form>

        {/* <Link className="forgot-password-text text-small" to="/password/forgot">
          Forgot Password?
        </Link> */}
        <NavLink
          className="forgot-password-text text-small"
          activeClassName="is-active"
          to="/password/forgot"
        >
          Forgot Password?
        </NavLink>
        {/* <span
          className="forgot-password-text text-small"
          onClick={() => history.push("/admin/manage-clients/")}
        >
          Forgot Password?
        </span> */}
      </div>

      <OTPModal
        isOpen={otpModalOpen}
        close={() => setOtpModalOpen(false)}
        from={location.state || { from: { pathname: "/" } }}
      />
    </div>
  );
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../../Components/Button/button";
import Input from "../../../../Components/Input/input";
import Modal from "../../../../Components/Modal/modal";
import { createClient, getClients } from "../../../../Redux/clients-slice";
import { hideLoader, showLoader } from "../../../../Redux/loader-slice";
import { useTranslation } from "react-i18next";
import CheckBox from "../../../../Components/CheckBox/checkbox";
import Select from "../../../../Components/Select/select";

export default function CreateClientModal(props) {
  const dispatch = useDispatch();

  const initialClientDetails = {
    Name: "",
    MaxUsers: "",
    IsTrial: false,
    Type: "",
    StartDate: "",
    ActiveUntil: null,
  };

  const initialClientUser = {
    Email: "",
    Password: "",
    Firstname: "",
    Lastname: "",
    Telephone: "",
    Role: "",
  };

  const [clientDetails, setClientDetails] = useState(initialClientDetails);
  const [clientUser, setClientUser] = useState(initialClientUser);

  // i18next
  const { t, i18n } = useTranslation("Errors");

  return (
    <Modal {...props} title="Create Client">
      <p>Client Details</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(showLoader());
          dispatch(
            createClient({ Client: clientDetails, Users: [clientUser] })
          ).then((res) => {
            if (res.payload.Success) {
              resetClientModal();
              props.close();

              dispatch(showLoader());
              dispatch(getClients()).then(() => dispatch(hideLoader()));
            } else {
              dispatch(hideLoader());
              // alert("Error creating client");
              alert(t(res.payload.ErrorCode));
            }
          });
        }}
      >
        <CheckBox
          required
          checked={clientDetails.IsTrial}
          onClick={() => {
            const currentChecked = clientDetails.IsTrial;
            // setClientDetails({
            //   ...clientDetails,
            //   IsTrial: !currentChecked,
            // });
            if (!currentChecked) {
              setClientDetails({ ...clientDetails, IsTrial: true, Type: 1 });
            } else {
              setClientDetails({ ...clientDetails, IsTrial: false, Type: "" });
            }
          }}
        />{" "}
        Trial
        <Input
          autoFocus
          required
          placeholder="Client Name"
          value={clientDetails.Name}
          onChange={(e) =>
            setClientDetails({ ...clientDetails, Name: e.target.value })
          }
        />
        <Input
          required
          type="number"
          placeholder="Users"
          value={clientDetails.MaxUsers}
          onChange={(e) =>
            setClientDetails({ ...clientDetails, MaxUsers: e.target.value })
          }
        />
        <Select
          placeholder="Client Type"
          value={clientDetails.Type}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e.target.value == 1) {
              setClientDetails({
                ...clientDetails,
                Type: e.target.value,
                IsTrial: true,
              });
            } else {
              setClientDetails({
                ...clientDetails,
                Type: e.target.value,
                IsTrial: false,
              });
            }
          }}
        >
          <optgroup label="Clients">
            <option value="1">Trial</option>
            <option value="2">Paying</option>
          </optgroup>
          <optgroup label="Other">
            <option value="3">Demo</option>
            <option value="4">Testing</option>
          </optgroup>
        </Select>
        <div style={{ padding: "10px" }}>
          <span>Start date:</span>
          <Input
            required
            type="date"
            value={clientDetails.StartDate}
            style={{ width: "100%" }}
            onChange={(e) =>
              setClientDetails({
                ...clientDetails,
                StartDate: e.target.value,
              })
            }
          />
        </div>
        <div style={{ padding: "10px" }}>
          <span>End date:</span>
          <Input
            type="date"
            value={clientDetails.ActiveUntil}
            style={{ width: "100%" }}
            onChange={(e) =>
              setClientDetails({
                ...clientDetails,
                ActiveUntil: e.target.value,
              })
            }
          />
        </div>
        <p>Client User</p>
        <Input
          required
          placeholder="First Name"
          value={clientUser.Firstname}
          onChange={(e) =>
            setClientUser({ ...clientUser, Firstname: e.target.value })
          }
        />
        <Input
          required
          placeholder="Last Name"
          value={clientUser.Lastname}
          onChange={(e) =>
            setClientUser({ ...clientUser, Lastname: e.target.value })
          }
        />
        <Input
          required
          type="email"
          placeholder="Email"
          value={clientUser.Email}
          onChange={(e) =>
            setClientUser({ ...clientUser, Email: e.target.value })
          }
        />
        <Input
          required
          type="password"
          placeholder="Password"
          value={clientUser.Password}
          onChange={(e) =>
            setClientUser({ ...clientUser, Password: e.target.value })
          }
        />
        <Input
          required
          placeholder="Phone Number"
          value={clientUser.Telephone}
          onChange={(e) =>
            setClientUser({ ...clientUser, Telephone: e.target.value })
          }
        />
        <Input
          placeholder="Role"
          value={clientUser.Role}
          onChange={(e) =>
            setClientUser({ ...clientUser, Role: e.target.value })
          }
        />
        <Button
          submit
          // onClick={() => {
          //   dispatch(showLoader());
          //   dispatch(
          //     createClient({ Client: clientDetails, Users: [clientUser] })
          //   ).then((res) => {
          //     if (res.payload.Success) {
          //       resetClientModal();
          //       props.close();
          //       dispatch(showLoader());
          //       dispatch(getClients()).then(() => dispatch(hideLoader()));
          //     } else {
          //       dispatch(hideLoader());
          //       // alert("Error creating client");
          //       alert(t(res.payload.ErrorCode));
          //     }
          //   });
          // }}
        >
          Submit
        </Button>
      </form>
    </Modal>
  );

  function resetClientModal() {
    setClientDetails(initialClientDetails);
    setClientUser(initialClientUser);
  }
}

import React from "react";
import "./checkbox.scss";

export default function CheckBox({ disabled, onClick, ...props }) {
  return (
    <div
      className={`checkbox${disabled ? " disabled" : ""}`}
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
      {...props}
    >
      <i
        className="fa fa-check"
        style={{ visibility: props.checked ? "visible" : "hidden" }}
      />
    </div>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoundedButton from "../../../../../Components/RoundedButton/rounded-button";
import "./environment-card.scss";
import CreateModeratorModal from "./CreateModeratorModal/create-moderator-modal";
import ToggleEnvironmentModal from "./ToggleEnvironmentModal/toggle-environment-modal";
import EditModeratorModal from "./EditModeratorModal/edit-moderator-modal";
import {
  getEnvironmentAutoLoginLink,
  getModerator,
} from "../../../../../Redux/environment-slice";
import { useTranslation } from "react-i18next";
import { combineReducers } from "redux";
import UpdateDailyJobModal, {
  updateDailyJobModal,
} from "../UpdateDailyJobModal/update-daily-job-modal";

export default function EnvironmentCard(props) {
  const [createModeratorModalOpen, setCreateModeratorModalOpen] =
    useState(false);
  const [chosenModeratorId, setChosenModeratorId] = useState();
  const [chosenModerator, setChosenModerator] = useState();
  const [editModeratorModalOpen, setEditModeratorModalOpen] = useState(false);
  const [toggleEnvironmentModalOpen, setToggleEnvironmentModalOpen] =
    useState(false);

  const dispatch = useDispatch();
  const autoLoginLink = useSelector(
    (state) => state.environmentReducer.autoLoginLink
  );

  const [openUpdateDailyJobModal, setOpenUpdateDailyJobModal] = useState(false);

  // i18next
  const { t, i18n } = useTranslation("Errors");

  const loadModeratorDetails = (moderatorId) => {
    // console.log("environment: ", props.environment.Id);
    // console.log("moderatorId: ", moderatorId);
    const data = {
      LicenceId: props.environment.Id,
      ModeratorId: moderatorId,
    };

    dispatch(getModerator(data)).then((res) => {
      if (res.payload.Success) {
        setChosenModerator(res.payload.Payload);
        setEditModeratorModalOpen(true);
      } else {
        alert("Error loading moderator");
      }
    });
  };

  return (
    <div className="environment-card">
      <a
        target="_blank"
        // href={"https://" + props.environment.Domain + ".hrpluscloud.com"}
        onClick={() => {
          if (props.environment.Moderators.length === 0) {
            alert("please create Moderator first");
          } else {
            const data = {
              EnvId: props.environment.Id,
              UserId: props.environment.Moderators[0].Id,
            };
            dispatch(getEnvironmentAutoLoginLink(data)).then((res) => {
              if (res.payload.Success) {
                // console.log("autoLoginLink: ", res.payload.Payload);
                window.open(res.payload.Payload, "_blank");
              } else {
                alert("Error: ", res.ErrorCode);
              }
            });
          }
        }}
      >
        <h4>{props.environment.Domain + ".hrpluscloud.com"}</h4>
      </a>
      <ul>
        <li>
          <span>Users:</span> <strong>{props.environment.TotalUsers}</strong>
        </li>
        <li>
          <span>Moderators:</span>{" "}
          <ul style={{ listStyleType: "inherit" }}>
            {props.environment.Moderators.map((moderator) => {
              return (
                <li
                  key={moderator.Id}
                  className={
                    "moderator-name" + (!props.client.Active ? " disabled" : "")
                  }
                  onClick={() => {
                    setChosenModeratorId(moderator.Id);
                    loadModeratorDetails(moderator.Id);
                  }}
                >
                  {moderator.FullName}
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
      <br />{" "}
      <RoundedButton
        icon="fa fa-pen"
        onClick={props.onClickEdit}
        disabled={!props.client.Active}
      />
      <RoundedButton
        icon="fa fa-plus"
        disabled={!props.client.Active}
        onClick={() => {
          setCreateModeratorModalOpen(true);
        }}
      />
      {/* <RoundedButton
        icon="fas fa-trash-alt"
        onClick={() => setRemoveEnvironmentModalOpen(true)}
      /> */}
      <RoundedButton
        icon="fas fa-bell"
        disabled={!props.client.Active}
        onClick={() => {
          setOpenUpdateDailyJobModal(true);
        }}
      />
      <RoundedButton
        icon="fa fa-power-off"
        iconstyle={{ color: props.environment.Active ? "green" : "red" }}
        disabled={!props.client.Active}
        onClick={() => {
          setToggleEnvironmentModalOpen(true);
        }}
      />
      <CreateModeratorModal
        environment={props.environment}
        clientId={props.client.Id}
        isOpen={createModeratorModalOpen}
        close={() => {
          setCreateModeratorModalOpen(false);
        }}
      />
      <EditModeratorModal
        environment={props.environment}
        clientId={props.client.Id}
        moderator={chosenModerator}
        isOpen={editModeratorModalOpen}
        close={() => {
          setEditModeratorModalOpen(false);
        }}
      />
      <ToggleEnvironmentModal
        environment={props.environment}
        clientId={props.client.Id}
        isOpen={toggleEnvironmentModalOpen}
        close={() => {
          setToggleEnvironmentModalOpen(false);
        }}
      />
      <UpdateDailyJobModal
        environment={props.environment}
        clientId={props.client.Id}
        dayOfDailyJob={props.environment.DayOfDailyJob}
        isOpen={openUpdateDailyJobModal}
        close={() => {
          setOpenUpdateDailyJobModal(false);
        }}
      />
    </div>
  );
}

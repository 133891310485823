import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    isActive: false,
  },
  reducers: {
    showLoader: (state) => {
      state.isActive = true;
    },
    hideLoader: (state) => {
      state.isActive = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;

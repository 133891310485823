import React from "react";
import "./modal.scss";

export default function Modal(props) {
  return props.isOpen ? (
    <div className={"modal " + props.className}>
      <div className="modal-bg" />
      <div className="modal-content">
        <span className="close-btn" onClick={props.close}>
          &times;
        </span>
        <h1>{props.title}</h1>
        {props.children}
      </div>
    </div>
  ) : null;
}

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import "../../EditEnvironmentModal/edit-environment-modal.scss";
import "./edit-add-user-modal.scss";

import Modal from "../../../../../../Components/Modal/modal";
import Input from "../../../../../../Components/Input/input";
import Button from "../../../../../../Components/Button/button";

import { editClientUser } from "../../../../../../Redux/clients-slice";
import { addClientUser } from "../../../../../../Redux/clients-slice";
import { getClient } from "../../../../../../Redux/clients-slice";

export const EditAddUserModal = (props) => {
  const { t, i18n } = useTranslation("Errors");
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({});
  useEffect(() => {
    setUserData(props.userData);
  }, [props.userData]);

  return (
    <div className="edit-environment-modal edit-user-modal">
      <Modal title={props.type + " Client User"} {...props}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (props.type === "Edit") {
              dispatch(editClientUser(userData)).then((res) => {
                if (res.payload.Success) {
                  dispatch(getClient(props.ClientId));
                  props.close();
                } else {
                  alert(t("Login.1030"));
                }
              });
            } else {
              dispatch(
                addClientUser({ ...userData, ClientId: props.ClientId })
              ).then((res) => {
                if (res.payload.Success) {
                  dispatch(getClient(props.ClientId));
                  props.close();
                } else {
                  alert(t("Login.1030"));
                }
              });
            }
          }}
        >
          <span className="input-edit">First Name</span>
          <div className="input-area">
            <Input
              required
              autoFocus
              value={userData.Firstname}
              onChange={(e) => {
                setUserData({ ...userData, Firstname: e.target.value });
              }}
            />
          </div>
          <span className="input-edit">Last Name</span>
          <div className="input-area">
            <Input
              required
              value={userData.Lastname}
              onChange={(e) => {
                setUserData({ ...userData, Lastname: e.target.value });
              }}
            />
          </div>
          <span className="input-edit">Role</span>
          <div className="input-area">
            <Input
              value={userData.Role}
              onChange={(e) => {
                setUserData({ ...userData, Role: e.target.value });
              }}
            />
          </div>
          <span className="input-edit">Email</span>
          <div className="input-area">
            <Input
              required
              type="email"
              value={userData.Email}
              onChange={(e) => {
                setUserData({ ...userData, Email: e.target.value });
              }}
            />
          </div>
          <span className="input-edit">Telephone</span>
          <div className="input-area">
            <Input
              required
              value={userData.Telephone}
              onChange={(e) => {
                setUserData({ ...userData, Telephone: e.target.value });
              }}
            />
          </div>
          {props.type === "Add" && (
            <div>
              <span className="input-edit">Password</span>

              <div className="input-area">
                <Input
                  required
                  type="password"
                  value={userData.Password}
                  onChange={(e) => {
                    setUserData({ ...userData, Password: e.target.value });
                  }}
                />
              </div>
            </div>
          )}
          <Button submit>Submit</Button>
        </form>
      </Modal>
    </div>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Utils/api";

export const getClients = createAsyncThunk("clients/getClients", async () => {
  return Api.get("/SuperAdmin/GetClients").then((res) => res.data);
});

export const getClient = createAsyncThunk(
  "clients/getClient",
  async (clientId) => {
    return Api.get("/Client/GetClient?Id=" + clientId).then((res) => res.data);
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (data) => {
    return Api.post("/SuperAdmin/CreateClient", data).then((res) => res.data);
  }
);

export const editClient = createAsyncThunk(
  "clients/editClient",
  async (data) => {
    return Api.post("/Client/UpdateClient", data).then((res) => res.data);
  }
);

export const toggleClientActivation = createAsyncThunk(
  "clients/toggleClientActivation",
  async (clientId) => {
    return Api.post(
      `/SuperAdmin/ToggleClientActivation?ClientId=${clientId}`
    ).then((res) => res.data);
  }
);

export const updateClientUser = createAsyncThunk(
  "clients/updateClientUser",
  async (data) => {
    return Api.post("/Client/UpdateClientUser", data).then((res) => res.data);
  }
);

export const getClientsStats = createAsyncThunk(
  "clients/getClientsStats",
  async (clientId) => {
    return Api.get("/SuperAdmin/GetClientsStats").then((res) => res.data);
  }
);

export const getNewClientsByDate = createAsyncThunk(
  "clients/getNewClientsByDate",
  async (weeksAgo) => {
    return Api.get("/SuperAdmin/GetNewClientsByDate?WeeksAgo=" + weeksAgo).then(
      (res) => res.data
    );
  }
);

export const editClientUser = createAsyncThunk(
  "/Client/UpdateClientUser",
  async (userData) => {
    return Api.post("/Client/UpdateClientUser", userData).then(
      (res) => res.data
    );
  }
);

export const addClientUser = createAsyncThunk(
  "/Client/AddClientUser",
  async (userData) => {
    return Api.post("/Client/AddClientUser", userData).then((res) => res.data);
  }
);

export const UpdateActiveClientUser = createAsyncThunk(
  "/Client/UpdateActiveClientUser",
  async (userData) => {
    return Api.post("/Client/UpdateActiveClientUser", userData).then(
      (res) => res.data
    );
  }
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState: {
    clients: [],
    client: {},
    stats: {},
    newClients: [],
  },
  reducers: {},
  extraReducers: {
    // Get Clients
    [getClients.fulfilled]: (state, action) => {
      state.clients = action.payload.Payload;
    },
    [getClients.rejected]: (state, action) => {},
    // GetClient
    [getClient.fulfilled]: (state, action) => {
      state.client = action.payload.Payload;
    },

    // GetClientsStats
    [getClientsStats.fulfilled]: (state, action) => {
      state.stats = action.payload.Payload;
    },

    // GetNewClientsByDate
    [getNewClientsByDate.fulfilled]: (state, action) => {
      state.newClients = action.payload.Payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {} = clientsSlice.actions;

export default clientsSlice.reducer;

import React from "react";
import Sidebar from "../Sidebar/sidebar";
import "./page-wrapper.scss";

export default function PageWrapper(props) {
  return (
    <div className="page-wrapper">
      <Sidebar />
      <div className="page-content">{props.children}</div>
    </div>
  );
}

import React, { useState } from "react";
import "./forgot-password.scss";

// Functions
import { useDispatch } from "react-redux";
import { changePasswordReq } from "../../../Redux/login-slice";
import { useTranslation } from "react-i18next";

// Components
import Input from "../../../Components/Input/input";
import Button from "../../../Components/Button/button";
import { Link } from "react-router-dom";

export default function ForgotPassword(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  // i18next
  const { t, i18n } = useTranslation("Errors");

  return (
    <div className="login">
      <div className="login-form">
        <h1>Password Change</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            // if (email.replace(/ /g, "") === "") {
            //   alert("Please enter Email");
            // } else {
            const data = {
              Email: email,
            };
            dispatch(changePasswordReq(data)).then((res) => {
              setSent(true);
              setTimeout(() => {
                props.history.push("/");
              }, 5000);
            });
            // }
          }}
        >
          {sent ? (
            <span>Password reset instructions sent to your email</span>
          ) : (
            <>
              <Input
                required
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button submit>Send</Button>
              <Link className="forgot-password-text text-small" to="/">
                Back to Login
              </Link>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./client-profile.scss";
// Functions
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../../../Redux/clients-slice";
import { hideLoader, showLoader } from "../../../../Redux/loader-slice";
// Components
import Box from "../../../../Components/Box/box";
import Title from "../../../../Components/Title/title";
import RoundedButton from "../../../../Components/RoundedButton/rounded-button";
import CreateEnvironmentModal from "./CreateEnvironmentModal/create-environment-modal";
import EnvironmentCard from "./EnvironmentCard/environment-card";
import { UsersCard } from "./UsersCard/users-card";
import EditEnvironmentModal from "./EditEnvironmentModal/edit-environment-modal";
import EditClientModal from "../EditClientModal/edit-client-modal";
import EditClientUserModal from "./EditClientUserModal/edit-client-user-modal";
import { EditAddUserModal } from "./UsersCard/EditUserModal/edit-add-user-modal";
import ToggleClientModal from "./ToggleClientModal/toggle-client-modal";
import Button from "../../../../Components/Button/button";

export default function ClientProfile(props) {
  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state.loginReducer.isAdmin);
  const clientId = useSelector((state) => state.loginReducer.clientId);
  const client = useSelector((state) => state.clientsReducer.client);

  const [createEnvironmentModalOpen, setCreateEnvironmentModalOpen] =
    useState(false);
  const [clientToEdit, setClientToEdit] = useState();
  const [environmentToEdit, setEnvironmentToEdit] = useState("");
  const [editClientModalOpen, setEditClientModalOpen] = useState(false);
  const [toggleClientModalOpen, setToggleClientModalOpen] = useState();

  const [showActive, setShowActive] = useState(true);

  useEffect(() => {
    dispatch(showLoader());
    if (isAdmin) {
      dispatch(getClient(props.match.params.id)).then(() => {
        dispatch(hideLoader());
      });
    } else {
      dispatch(getClient(clientId)).then(() => {
        dispatch(hideLoader());
      });
    }
  }, []);

  return (
    <div className="client-profile">
      <Title>{client.Name}</Title>
      <div className="box-wrapper">
        {/* General Details */}
        <Box width={400}>
          <h3>General Details</h3>
          <br />
          <ul>
            {!client.Active && <li style={{ color: "red" }}>Inactive</li>}
            {client.IsTrial &&
              (client.DaysLeft > 0 ? (
                <li>Free Trial (expires in {client.DaysLeft} days)</li>
              ) : (
                <li>
                  Free Trial <span style={{ color: "red" }}> (expired)</span>
                </li>
              ))}
            <li>Joined: {new Date(client.CreatedOn).toLocaleDateString()}</li>
            <li>
              Start Date: {new Date(client.StartDate).toLocaleDateString()}
            </li>
            {client.ActiveUntil && (
              <li>
                End Date: {new Date(client.ActiveUntil).toLocaleDateString()}
              </li>
            )}

            <li>
              Usage: <strong>{client.TotalUsers}</strong>/{client.MaxUsers}{" "}
              Users
            </li>
            {/* <li>
              <ul>
                <span>Users:</span>{" "}
                {client.Users &&
                  client.Users.map((User) => {
                    return (
                      <li
                        key={User.Id}
                        className={
                          "user-name" + (!client.Active ? " disabled" : "")
                        }
                        onClick={() => {
                          setClientUser(User);
                          setEditClientUserModalOpen(true);
                        }}
                      >
                        {User.FirstName} {User.LastName}
                      </li>
                    );
                  })}
              </ul>
            </li> */}
          </ul>
          <br />
          <RoundedButton
            icon="fa fa-pen"
            // disabled={!client.Active}
            onClick={() => {
              setClientToEdit(client);
              setEditClientModalOpen(true);
            }}
          />
          {isAdmin && (
            <RoundedButton
              icon="fa fa-power-off"
              iconstyle={{ color: client.Active ? "green" : "red" }}
              onClick={() => {
                setClientToEdit(client);
                setToggleClientModalOpen(true);
              }}
            />
          )}
        </Box>
        {/* Environments */}
        <Box width={400}>
          <h3>Environments</h3>

          <div className="environments-wrapper">{}</div>

          <br />
          <RoundedButton
            primary="true"
            icon="fa fa-plus"
            disabled={!client.Active}
            onClick={() => {
              setCreateEnvironmentModalOpen(true);
            }}
          />

          {client.Environments &&
            client.Environments.map((environment, index) => (
              <EnvironmentCard
                key={index}
                environment={environment}
                onClickEdit={
                  () => {
                    setEnvironmentToEdit(environment);
                  }
                  //  setEnvironmentToEdit(environment)
                }
                // }
                // clientId={client.Id}
                client={client}
              />
            ))}
        </Box>

        {/* Transaction History */}
        <div>
          {/* <Box width={400}>
            <h3>Transaction History</h3>
            <br />
            <ul>
              <li>Date - Price</li>
            </ul>
          </Box> */}

          {/* Users */}
          <Box width={400}>
            <h3>Users</h3>

            <div className="environments-wrapper users-wrapper">{}</div>

            <br />
            <table className="filter-by-active">
              <thead></thead>
              <tbody>
                <tr>
                  <td>
                    <Button
                      style={showActive ? {} : { backgroundColor: "gray" }}
                      onClick={() => {
                        setShowActive(true);
                      }}
                    >
                      Contacts
                    </Button>
                  </td>
                  <td>
                    <Button
                      style={!showActive ? {} : { backgroundColor: "gray" }}
                      onClick={() => {
                        setShowActive(false);
                      }}
                    >
                      Contacts Archive
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>

            <UsersCard
              users={client.Users}
              client={client}
              showActive={showActive}
            />
          </Box>
        </div>
      </div>
      <CreateEnvironmentModal
        clientId={isAdmin ? props.match.params.id : clientId}
        isClientTrial={client.IsTrial}
        isOpen={createEnvironmentModalOpen}
        close={() => setCreateEnvironmentModalOpen(false)}
      />
      {environmentToEdit && (
        <EditEnvironmentModal
          clientId={isAdmin ? props.match.params.id : clientId}
          isClientTrial={client.IsTrial}
          environment={environmentToEdit}
          isOpen={true}
          close={() => setEnvironmentToEdit("")}
        />
      )}

      <EditClientModal
        isOpen={editClientModalOpen}
        close={() => setEditClientModalOpen(false)}
        client={clientToEdit}
      />

      {/* <EditClientUserModal
        clientId={isAdmin ? props.match.params.id : clientId}
        clientUser={clientUser}
        isOpen={editClientUserModalOpen}
        close={() => setEditClientUserModalOpen(false)}
      /> */}

      <ToggleClientModal
        client={client}
        isOpen={toggleClientModalOpen}
        close={() => setToggleClientModalOpen(false)}
      />
    </div>
  );
}

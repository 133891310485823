import React from "react";
import { useHistory } from "react-router";
import "./client-box.scss";

export default function ClientBox(props) {
  const history = useHistory();

  return (
    <div
      className="client-box"
      onClick={() => history.push("/admin/manage-clients/" + props.client.Id)}
    >
      <h3 className={"name" + (!props.client.Active ? " disabled" : "")}>
        {props.client.Name}
      </h3>
      <div className="indicator-buttons">
        {props.client.Type === 2 && <i className="fas fa-dollar-sign"></i>}
        {/* <i className="fa fa-exclamation"></i> */}

        {/* {props.client.Active && <i className="fas fa-circle"></i>} */}
      </div>
      <p>
        Active users:{" "}
        <strong
          style={{
            color:
              props.client.ActiveUsers > props.client.UsersLimit ? "red" : "",
          }}
        >
          {props.client.ActiveUsers}
        </strong>
      </p>
      <p>
        Inactive users: <strong>{props.client.InactiveUsers}</strong>
      </p>
      <p>
        Users limit: <strong>{props.client.UsersLimit}</strong>
      </p>
    </div>
  );
}

import React from "react";
// Functions
import "./toggle-environment-modal.scss";
import { useDispatch } from "react-redux";
// Components
import Button from "../../../../../../Components/Button/button";
import Modal from "../../../../../../Components/Modal/modal";
import { toggleEnvironmentActivation } from "../../../../../../Redux/environment-slice";
import { hideLoader, showLoader } from "../../../../../../Redux/loader-slice";
import { getClient } from "../../../../../../Redux/clients-slice";
import { useTranslation } from "react-i18next";

export default function ToggleEnvironmentModal(props) {
  const dispatch = useDispatch();
  // i18next
  const { t, i18n } = useTranslation("Errors");

  return (
    <div>
      <Modal
        title={
          props.environment.Active
            ? "Turn off Environment"
            : "Turn on Environment"
        }
        {...props}
      >
        <div style={{ textAlign: "center" }}>
          {props.environment.Active ? (
            <span>
              {`Are you sure you want to turn off the environment ${
                props.environment.Domain + ".hrpluscloud.com"
              }?`}
            </span>
          ) : (
            <span>
              {`Are you sure you want to turn on the environment ${
                props.environment.Domain + ".hrpluscloud.com"
              }?`}
            </span>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ display: "inline-block", margin: "0 10px" }}
            onClick={doToggleEnvironment}
          >
            {props.environment.Active ? "Turn off" : "Turn on"}
          </Button>
          <Button
            style={{ display: "inline-block" }}
            onClick={() => props.close()}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );

  function doToggleEnvironment() {
    dispatch(showLoader());
    const data = {};
    dispatch(toggleEnvironmentActivation(props.environment.Id)).then((res) => {
      if (res.payload.Success) {
        dispatch(hideLoader());
        dispatch(getClient(props.clientId));
        props.close();
      } else {
        dispatch(hideLoader());
        // alert("Error toggling environment");
        alert(t(res.payload.ErrorCode));
      }
    });
  }
}

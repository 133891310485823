import React from "react";
import Title from "../../../Components/Title/title";
import "./client-home-page.scss";

export default function ClientHomePage() {
  return (
    <div className="client-home-page">
      <Title>Home Page</Title>
    </div>
  );
}

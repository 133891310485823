import axios from "axios";
import { checkIEorEdge } from "./func";
import history from "./history";
//import store from "../Redux/store";
import { doSignOut } from "../Redux/login-slice";
// ...

const Api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + "/api/",
});

const IntegrateApiInterceptor = (store) => {
  const checkAuth = (status) => {
    if (status === 401) {
      // asd.push("/login");
      store.dispatch(doSignOut());
      // history.push("/signout"); ///send with some state
    }
  };
  Api.interceptors.request.use(
    function (config) {
      if (!checkIEorEdge()) return config;

      let url = config.url;
      let parts = url.split("?");
      config.url =
        `${parts[0]}?ts=${new Date().getTime()}` +
        (parts.length > 1 ? `&${parts[1]}` : "");
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  Api.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      if (error && error.response) {
        checkAuth(error.response.status);
      }
      return Promise.reject(error);
    }
  );
};
export default Api;
export { IntegrateApiInterceptor };

import React, { useEffect, useState } from "react";

// Functions
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { doSignOut } from "../../Redux/login-slice";

export default function SignOut() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(doSignOut());
  }, []);
  return <Redirect to="/" />;
}

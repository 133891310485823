import React from "react";
import "../EnvironmentCard/environment-card.scss";
import "./user-card.scss";
import { useState } from "react";

import { EditAddUserModal } from "./EditUserModal/edit-add-user-modal";
import { SetActiveModal } from "./SetActiveModal/set-active-modal";
import RoundedButton from "../../../../../Components/RoundedButton/rounded-button";

export const UsersCard = (props) => {
  const [userData, setUserData] = useState({
    Id: null,
    firstname: "",
    lastname: "",
    role: "",
    email: "",
    phone: "",
    Active: false,
  });

  const [openActiveModal, setOpenActiveModal] = useState(false);
  const [editAddUserModal, setEditAddUserModal] = useState({
    open: false,
    type: "",
  });

  return (
    <div className="environment-card users-card">
      {props.showActive && (
        <RoundedButton
          primary="true"
          icon="fa fa-plus"
          style={{ float: "right" }}
          disabled={!props.client.Active}
          onClick={() => {
            setEditAddUserModal({ open: true, type: "Add" });
          }}
        />
      )}
      {props.users && props.users.length > 0 && (
        <ul className="users-list">
          <li>
            {props.users.map((user) => {
              return (
                <div
                  key={user.Id}
                  className={
                    user.Active === props.showActive ? "show-user" : "hide-user"
                  }
                >
                  <span
                    className="user-name"
                    onClick={() => {
                      setEditAddUserModal({ open: true, type: "Edit" });
                      setUserData({
                        Id: user.Id,
                        Firstname: user.FirstName ? user.FirstName : "",
                        Lastname: user.LastName ? user.LastName : "",
                        Role: user.Role ? user.Role : "",
                        Email: user.Email ? user.Email : "",
                        Telephone: user.Telephone ? user.Telephone : "",
                        Active: user.Active,
                      });
                    }}
                  >
                    {user.FirstName + " " + user.LastName}
                  </span>
                  <RoundedButton
                    icon="fa fa-pen"
                    onClick={() => {
                      setEditAddUserModal({ open: true, type: "Edit" });
                      setUserData({
                        Id: user.Id,
                        Firstname: user.FirstName ? user.FirstName : "",
                        Lastname: user.LastName ? user.LastName : "",
                        Role: user.Role ? user.Role : "",
                        Email: user.Email ? user.Email : "",
                        Telephone: user.Telephone ? user.Telephone : "",
                        Active: user.Active,
                      });
                    }}
                    disabled={!props.client.Active}
                  />
                  <RoundedButton
                    icon="fa fa-power-off"
                    iconstyle={{ color: user.Active ? "green" : "red" }}
                    onClick={() => {
                      setOpenActiveModal(true);
                      setUserData({
                        Id: user.Id,
                        Active: user.Active,
                      });
                    }}
                  />
                </div>
              );
            })}
          </li>
        </ul>
      )}
      <br />
      <EditAddUserModal
        type={editAddUserModal.type}
        isOpen={editAddUserModal.open}
        userData={userData}
        ClientId={props.client.Id}
        close={() => {
          setEditAddUserModal({ open: false, type: "" });
          setUserData({
            Id: null,
            firstName: "",
            lastName: "",
            role: "",
            email: "",
            phone: "",
            Active: false,
          });
        }}
      />

      <SetActiveModal
        isOpen={openActiveModal}
        userId={userData.Id}
        activeStatus={userData.Active}
        ClientId={props.client.Id}
        close={() => {
          setOpenActiveModal(false);
          setUserData({
            Id: null,
            firstName: "",
            lastName: "",
            role: "",
            email: "",
            phone: "",
            Active: false,
          });
        }}
      />
    </div>
  );
};

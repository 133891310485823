import React, { useState } from "react";
// Functions
import "./create-moderator-modal.scss";
import { useDispatch, useSelector } from "react-redux";
// Components
import Button from "../../../../../../Components/Button/button";
import CheckBox from "../../../../../../Components/CheckBox/checkbox";
import Input from "../../../../../../Components/Input/input";
import Modal from "../../../../../../Components/Modal/modal";
import { createModerator } from "../../../../../../Redux/environment-slice";
import { hideLoader, showLoader } from "../../../../../../Redux/loader-slice";
import { getClient } from "../../../../../../Redux/clients-slice";
import { useTranslation } from "react-i18next";

export default function CreateModeratorModal(props) {
  const dispatch = useDispatch();

  const loginUserFirstName = useSelector(
    (state) => state.loginReducer.firstName
  );
  const loginUserLastName = useSelector((state) => state.loginReducer.lastName);
  const loginUserEmail = useSelector((state) => state.loginReducer.email);
  const loginUserTelephone = useSelector(
    (state) => state.loginReducer.telephone
  );

  const initialModeratorDetails = {
    LicenceId: props.environment.Id,
    User: {
      FirstName: "",
      LastName: "",
      Email: "",
      Telephone: "",
      IdNumber: "",
      SendFirstMessage: false,
    },
  };

  const [moderatorDetails, setModeratorDetails] = useState(
    initialModeratorDetails
  );
  const [loginUserDetailsChecked, setLoginUserDetailsChecked] = useState(false);
  // i18next
  const { t, i18n } = useTranslation("Errors");

  const toggleLoginUserDetails = () => {
    const sendFirstMessageChecked = moderatorDetails.User.SendFirstMessage;
    if (loginUserDetailsChecked) {
      initialModeratorDetails.User.SendFirstMessage = sendFirstMessageChecked;
      setModeratorDetails(initialModeratorDetails);
      setLoginUserDetailsChecked(false);
    } else {
      setModeratorDetails({
        LicenceId: props.environment.Id,
        User: {
          FirstName: loginUserFirstName,
          LastName: loginUserLastName,
          Email: loginUserEmail,
          Telephone: loginUserTelephone === null ? "" : loginUserTelephone,
          IdNumber: "",
          SendFirstMessage: sendFirstMessageChecked,
        },
      });
      setLoginUserDetailsChecked(true);
    }
  };

  return (
    <div>
      <Modal title="Create Moderator" {...props}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doCreateModerator();
          }}
        >
          <CheckBox
            checked={moderatorDetails.User.SendFirstMessage}
            onClick={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: {
                  ...moderatorDetails.User,
                  SendFirstMessage: !moderatorDetails.User.SendFirstMessage,
                },
              });
            }}
          />
          Send Welcome Notification
          <br />
          <CheckBox
            checked={loginUserDetailsChecked}
            onClick={() => toggleLoginUserDetails()}
          />
          Use My Details
          <p>Moderator Details</p>
          <Input
            required
            autoFocus
            value={moderatorDetails.User.FirstName}
            placeholder="First Name"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, FirstName: e.target.value },
              });
            }}
          />
          <Input
            required
            value={moderatorDetails.User.LastName}
            placeholder="Last Name"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, LastName: e.target.value },
              });
            }}
          />
          <Input
            required
            value={moderatorDetails.User.Email}
            placeholder="Email"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, Email: e.target.value },
              });
            }}
            type="email"
          />
          <Input
            required
            value={moderatorDetails.User.Telephone}
            placeholder="Telephone"
            type="number"
            className="telephone-input"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, Telephone: e.target.value },
              });
            }}
          />
          <Input
            required
            value={moderatorDetails.User.IdNumber}
            placeholder="IdNumber"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, IdNumber: e.target.value },
              });
            }}
          />
          <Button
            submit
            // onClick={doCreateModerator}
          >
            Submit
          </Button>
        </form>
      </Modal>
    </div>
  );

  function doCreateModerator() {
    // Check Fields
    // if (moderatorDetails.User.FirstName.replace(/ /g, "") === "") {
    //   alert("Please enter First Name");
    // } else if (moderatorDetails.User.LastName.replace(/ /g, "") === "") {
    //   alert("Please enter Last Name");
    // } else if (moderatorDetails.User.Email.replace(/ /g, "") === "") {
    //   alert("Please enter Email");
    // } else if (moderatorDetails.User.Telephone.replace(/ /g, "") === "") {
    //   alert("Please enter Telephone");
    // } else if (moderatorDetails.User.IdNumber.replace(/ /g, "") === "") {
    //   alert("Please enter IdNumber");
    // } else {

    dispatch(showLoader());
    const data = {};
    dispatch(createModerator(moderatorDetails)).then((res) => {
      if (res.payload.Success) {
        setModeratorDetails(initialModeratorDetails);
        setLoginUserDetailsChecked(false);
        dispatch(getClient(props.clientId));
        dispatch(hideLoader());
        props.close();
      } else {
        // console.log("res: ", res.payload.ErrorCode);
        dispatch(hideLoader());
        alert(t(res.payload.ErrorCode));

        // alert("Error creating moderator");
      }
    });
    // }
  }
}

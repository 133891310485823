import React, { useState, useEffect } from "react";
// Functions
import "./edit-moderator-modal.scss";
import { useDispatch } from "react-redux";
// Components
import Button from "../../../../../../Components/Button/button";
import Input from "../../../../../../Components/Input/input";
import Modal from "../../../../../../Components/Modal/modal";
import {
  updateModerator,
  sendFirstMessageToUser,
  sendChangePasswordMessageToUser,
} from "../../../../../../Redux/environment-slice";
import { hideLoader, showLoader } from "../../../../../../Redux/loader-slice";
import { getClient } from "../../../../../../Redux/clients-slice";
import { useTranslation } from "react-i18next";

export default function EditModeratorModal(props) {
  const dispatch = useDispatch();

  const initialModeratorDetails = {
    LicenceId: props.environment.Id,
    User: {
      Id: "",
      FirstName: "",
      LastName: "",
      Email: "",
      Telephone: "",
      IdNumber: "",
      SendFirstMessage: false,
    },
  };

  const [moderatorDetails, setModeratorDetails] = useState(
    initialModeratorDetails
  );

  // i18next
  const { t, i18n } = useTranslation("Errors");

  useEffect(() => {
    if (props.moderator !== undefined) {
      setModeratorDetails({
        LicenceId: props.environment.Id,
        User: {
          Id: props.moderator.Id,
          FirstName: props.moderator.FirstName,
          LastName: props.moderator.LastName,
          Email: props.moderator.Email,
          Telephone: props.moderator.Telephone,
          IdNumber: props.moderator.IdNumber,
        },
      });
    }
  }, [props.moderator]);

  return (
    <div>
      <Modal title="Edit Moderator" {...props}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doUpdateModerator();
          }}
        >
          <Input
            required
            autoFocus
            value={moderatorDetails.User.FirstName}
            placeholder="First Name"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, FirstName: e.target.value },
              });
            }}
          />
          <Input
            required
            value={moderatorDetails.User.LastName}
            placeholder="Last Name"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, LastName: e.target.value },
              });
            }}
          />
          <Input
            required
            value={moderatorDetails.User.Email}
            placeholder="Email"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, Email: e.target.value },
              });
            }}
          />
          <Input
            required
            value={moderatorDetails.User.Telephone}
            placeholder="Telephone"
            type="number"
            className="telephone-input"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, Telephone: e.target.value },
              });
            }}
          />
          <Input
            required
            value={moderatorDetails.User.IdNumber}
            placeholder="IdNumber"
            onChange={(e) => {
              setModeratorDetails({
                ...moderatorDetails,
                User: { ...moderatorDetails.User, IdNumber: e.target.value },
              });
            }}
          />
          <Button onClick={doSendFirstMessageToUser} type="button">
            Send first message email
          </Button>
          <Button onClick={doSendChangePasswordMessageToUser} type="button">
            Send forgot password email
          </Button>

          <Button
            submit
            // onClick={doUpdateModerator}
          >
            Submit
          </Button>
        </form>
      </Modal>
    </div>
  );

  function doSendFirstMessageToUser() {
    dispatch(showLoader());
    const data = {
      LicenceId: moderatorDetails.LicenceId,
      ModeratorId: moderatorDetails.User.Id,
    };
    dispatch(sendFirstMessageToUser(data)).then((res) => {
      if (res.payload.Success) {
        setModeratorDetails(initialModeratorDetails);
        dispatch(hideLoader());
        props.close();
      } else {
        dispatch(hideLoader());
        alert(t(res.payload.ErrorCode));
        // alert("Error sending first message");
      }
    });
  }

  function doSendChangePasswordMessageToUser() {
    dispatch(showLoader());
    const data = {
      LicenceId: moderatorDetails.LicenceId,
      ModeratorId: moderatorDetails.User.Id,
    };
    dispatch(sendChangePasswordMessageToUser(data)).then((res) => {
      if (res.payload.Success) {
        setModeratorDetails(initialModeratorDetails);
        dispatch(hideLoader());
        props.close();
      } else {
        dispatch(hideLoader());
        alert(t(res.payload.ErrorCode));
        // alert("Error sending forgot password message");
      }
    });
  }

  function doUpdateModerator() {
    // Check Fields
    // if (moderatorDetails.User.FirstName.replace(/ /g, "") === "") {
    //   alert("Please enter First Name");
    // } else if (moderatorDetails.User.LastName.replace(/ /g, "") === "") {
    //   alert("Please enter Last Name");
    // } else if (moderatorDetails.User.Email.replace(/ /g, "") === "") {
    //   alert("Please enter Email");
    // } else if (moderatorDetails.User.Telephone.replace(/ /g, "") === "") {
    //   alert("Please enter Telephone");
    // } else if (moderatorDetails.User.IdNumber.replace(/ /g, "") === "") {
    //   alert("Please enter IdNumber");
    // } else {
    dispatch(showLoader());
    const data = {};
    dispatch(updateModerator(moderatorDetails)).then((res) => {
      if (res.payload.Success) {
        setModeratorDetails(initialModeratorDetails);
        dispatch(getClient(props.clientId));
        dispatch(hideLoader());
        props.close();
      } else {
        dispatch(hideLoader());
        alert(t(res.payload.ErrorCode));
        // alert("Error editing moderator");
      }
    });
    // }
  }
}

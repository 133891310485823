import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Utils/api";
export const doSignOut = createAsyncThunk("login/doSignOut", async (data) => {
  return Api.get("/Login/SignOut", data).then((res) => res.data);
});
export const getLogin = createAsyncThunk("login/getLogin", async (data) => {
  return Api.post("/Login/GetLogin", data).then((res) => res.data);
});

export const getLoginOtp = createAsyncThunk(
  "login/getLoginOtp",
  async (data) => {
    return Api.post("/Login/OTP", data).then((res) => res.data);
  }
);
export const CheckLogin = createAsyncThunk("login/CheckLogin", async () => {
  return Api.get("/Login/CheckLogin").then((res) => res.data);
});

export const changePasswordReq = createAsyncThunk(
  "login/changePasswordReq",
  async (data) => {
    return Api.post("/Login/ChangePasswordReq", data).then((res) => res.data);
  }
);

export const cangePasswordAction = createAsyncThunk(
  "login/changePasswordAction",
  async (data) => {
    return Api.post("/Login/ChangePasswordAction", data).then(
      (res) => res.data
    );
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    ini: false,
    isLoggedIn: false,
    isAdmin: false,
    clientId: "",
    clientName: "",
    firstName: "",
    lastName: "",
    userId: "",
    email: "",
    telephone: "",
  },
  reducers: {
    logIn: (state) => {
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
    },
  },
  extraReducers: {
    [getLogin.pending]: (state, action) => {},
    [getLogin.fulfilled]: (state, action) => {},
    [getLogin.rejected]: (state, action) => {},
    [CheckLogin.rejected]: (state, action) => {
      state.ini = true;
    },
    [CheckLogin.fulfilled]: (state, action) => {
      //set all info. maybe use the logIn Reducer
      state.ini = true;
      if (action.payload.Success === true) {
        const PayLoad = action.payload.Payload;

        state.isLoggedIn = true;
        state.isAdmin = PayLoad.IsAdmin;
        state.clientId = PayLoad.ClientId;
        state.clientName = PayLoad.ClientName;
        state.firstName = PayLoad.FirstName;
        state.lastName = PayLoad.LastName;
        state.userId = PayLoad.UserId;
        state.email = PayLoad.Email;
        state.telephone = PayLoad.Telephone;
      }
    },
    [getLoginOtp.fulfilled]: (state, action) => {
      //set all info. maybe use the logIn Reducer
      if (action.payload.Success === true) {
        const PayLoad = action.payload.Payload;
        state.isLoggedIn = true;
        state.isAdmin = PayLoad.IsAdmin;
        state.clientId = PayLoad.ClientId;
        state.clientName = PayLoad.ClientName;
        state.firstName = PayLoad.FirstName;
        state.lastName = PayLoad.LastName;
        state.userId = PayLoad.UserId;
        state.email = PayLoad.Email;
        state.telephone = PayLoad.Telephone;
      }
    },
    [doSignOut.fulfilled]: (state, action) => {
      //set all info. maybe use the logIn Reducer
      if (action.payload.Success === true) state.isLoggedIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut } = loginSlice.actions;

export default loginSlice.reducer;

import React, { useEffect, useState } from "react";
// Functions
import "./edit-environment-modal.scss";
import { useDispatch } from "react-redux";
// Components
import Button from "../../../../../Components/Button/button";
import CheckBox from "../../../../../Components/CheckBox/checkbox";
import Input from "../../../../../Components/Input/input";
import Modal from "../../../../../Components/Modal/modal";
import Select from "../../../../../Components/Select/select";
import { hideLoader, showLoader } from "../../../../../Redux/loader-slice";
import { editEnvironment } from "../../../../../Redux/environment-slice";
import { getClient } from "../../../../../Redux/clients-slice";
import { useTranslation } from "react-i18next";

export default function EditEnvironmentModal(props) {
  const dispatch = useDispatch();
  // i18next
  const { t, i18n } = useTranslation("Errors");

  const initialEnvironmentDetails = props.environment;

  const [environmentDetails, setEnvironmentDetails] = useState(
    initialEnvironmentDetails
  );

  const [defaulLoginMethod, setDefaulLoginMethod] = useState(
    environmentDetails.DefaultLoginMethod === 1 ? "Email" : "SMS"
  );

  return (
    <div className="edit-environment-modal">
      <Modal title="Edit Environment" {...props}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doEditEnvironment();
            setDefaulLoginMethod("Email");
          }}
        >
          <div className="environment-domain-input">
            <Input
              required
              autoFocus
              value={environmentDetails.Domain}
              onChange={(e) => {
                setEnvironmentDetails({
                  ...environmentDetails,
                  Domain: e.target.value,
                });
              }}
            />
          </div>
          {/* Active Until */}
          {/* <div className="environment-date-input">
          <CheckBox
            checked={environmentDetails.HasExpirationDate}
            onClick={() => {
              setEnvironmentDetails({
                ...environmentDetails,
                HasExpirationDate: !environmentDetails.HasExpirationDate,
              });
            }}
          />
          <span>Expires</span>
          <Input
            disabled={!environmentDetails.HasExpirationDate}
            type="date"
            value={environmentDetails.ActiveUntil}
            onChange={(e) => {
              setEnvironmentDetails({
                ...environmentDetails,
                ActiveUntil: e.target.value,
              });
            }}
          />
        </div> */}
          {/* Onboarding Checkbox */}
          <br />
          <CheckBox
            checked={environmentDetails.OnBoarding}
            onClick={() => {
              setEnvironmentDetails({
                ...environmentDetails,
                OnBoarding: !environmentDetails.OnBoarding,
              });
            }}
          />
          Onboarding
          <br />
          {/* Peva Checkbox */}
          <CheckBox
            checked={environmentDetails.Evaluation}
            onClick={() => {
              setEnvironmentDetails({
                ...environmentDetails,
                Evaluation: !environmentDetails.Evaluation,
              });
            }}
          />
          Performance Evaluation
          <br />
          {/* LXP Checkbox */}
          <CheckBox
            checked={environmentDetails.LXP}
            onClick={() => {
              setEnvironmentDetails({
                ...environmentDetails,
                LXP: !environmentDetails.LXP,
              });
            }}
          />
          LXP
          <br />
          {/* LXP Checkbox */}
          <CheckBox
            checked={environmentDetails.LXPAI}
            onClick={() => {
              setEnvironmentDetails({
                ...environmentDetails,
                LXPAI: !environmentDetails.LXPAI,
              });
            }}
          />
          LXP with AI
          <br />
          <CheckBox
            required
            checked={environmentDetails.Skills}
            onClick={() => {
              setEnvironmentDetails({
                ...environmentDetails,
                Skills: !environmentDetails.Skills,
              });
            }}
          />
          Skills
          <br />
          <br />
          <CheckBox
            checked={environmentDetails.EmailLogin}
            onClick={() => {
              setEnvironmentDetails({
                ...environmentDetails,
                EmailLogin: !environmentDetails.EmailLogin,
              });
            }}
          />
          Email Login
          <br />
          {!props.isClientTrial && (
            <>
              <CheckBox
                checked={environmentDetails.SMSLogin}
                onClick={() => {
                  setEnvironmentDetails({
                    ...environmentDetails,
                    SMSLogin: !environmentDetails.SMSLogin,
                  });
                }}
              />
              SMS Login
            </>
          )}
          {environmentDetails.SMSLogin && environmentDetails.EmailLogin && (
            <div>
              <div className="default-login-method">
                <Select
                  style={{ width: "25%", float: "left" }}
                  placeholder="Select a method"
                  value={defaulLoginMethod}
                  onChange={(e) => setDefaulLoginMethod(e.target.value)}
                >
                  <option value="Email">Email</option>
                  <option value="SMS">SMS</option>
                </Select>
                <div className="default-login-method-title">
                  Default login method
                </div>
              </div>
            </div>
          )}
          {environmentDetails.LXPAI && (
            <div className="Lxp-AI-Extras">
              <div className="Lxp-AI-Extra">
                <Input
                  value={environmentDetails.NumberOfAIInterviews}
                  onChange={(e) => {
                    setEnvironmentDetails({
                      ...environmentDetails,
                      NumberOfAIInterviews: e.target.value,
                    });
                  }}
                  type="number"
                  placeholder="Enter number of AI interviews"
                />
              </div>
            </div>
          )}
          {/* <Select
          value={environmentDetails.SMSLogin ? "SMS" : "Email"}
          placeholder="Login Type"
          onChange={(e) =>
            setEnvironmentDetails({
              ...environmentDetails,
              SMSLogin: e.target.value === "SMS" ? true : false,
            })
          }
        >
          <option value="Email">Email</option>
          <option value="SMS">SMS</option>
        </Select> */}
          <Button
            submit
            // onClick={doEditEnvironment}
          >
            Submit
          </Button>
        </form>
      </Modal>
    </div>
  );

  function doEditEnvironment() {
    // console.log("environmentDetails.SMSLogin: ", environmentDetails.SMSLogin);
    // console.log(
    //   "environmentDetails.EmailLogin: ",
    //   environmentDetails.EmailLogin
    // );

    // Check Fields
    // if (environmentDetails.Domain.replace(/ /g, "") === "") {
    //   alert("Please enter sub-domain");
    // } else
    if (
      environmentDetails.OnBoarding === false &&
      environmentDetails.Evaluation === false &&
      environmentDetails.LXP === false &&
      environmentDetails.Skills === false &&
      environmentDetails.LXPAI === false
    ) {
      alert("Please choose system");
    } else if (
      environmentDetails.SMSLogin === false &&
      environmentDetails.EmailLogin === false
    ) {
      alert("Please choose login type");
    } else {
      // Edit Environment
      dispatch(showLoader());

      dispatch(
        editEnvironment({
          ...environmentDetails,
          ClientId: props.clientId,
          DefaultLoginMethod:
            environmentDetails.SMSLogin && environmentDetails.EmailLogin
              ? defaulLoginMethod === "Email"
                ? 1
                : 2
              : -1,
          NumberOfAIInterviews: environmentDetails.NumberOfAIInterviews
            ? parseInt(environmentDetails.NumberOfAIInterviews)
            : null,
        })
      ).then((res) => {
        if (res.payload.Success) {
          dispatch(getClient(props.clientId)).then(() => {
            dispatch(hideLoader());
            setEnvironmentDetails(initialEnvironmentDetails);
            props.close();
          });
        } else {
          dispatch(hideLoader());
          // alert("Error creating environment");
          alert(t(res.payload.ErrorCode));
        }
      });
    }
  }
}

import React from "react";
import "./select.scss";

export default function Select(props) {
  return (
    <select {...props} className="select" defaultValue="">
      <option disabled value="">
        {props.placeholder}
      </option>
      {props.children}
    </select>
  );
}

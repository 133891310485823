import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button/button";
import Input from "../../../../Components/Input/input";
import Modal from "../../../../Components/Modal/modal";
import { editClient, getClient } from "../../../../Redux/clients-slice";
import { hideLoader, showLoader } from "../../../../Redux/loader-slice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Select from "../../../../Components/Select/select";

export default function EditClientModal(props) {
  const dispatch = useDispatch();
  // i18next
  const { t, i18n } = useTranslation("Errors");

  const isAdmin = useSelector((state) => state.loginReducer.isAdmin);
  const client = useSelector((state) => state.clientsReducer.client);

  const initialClientDetails = {
    Id: "",
    Name: "",
    MaxUsers: "",
    Type: "",
    StartDate: "",
    ActiveUntil: null,
  };

  const initialClientUser = {
    Email: "",
    Password: "",
    Firstname: "",
    Lastname: "",
    Telephone: "",
  };

  const [clientDetails, setClientDetails] = useState(initialClientDetails);
  const [clientUser, setClientUser] = useState(initialClientUser);

  useEffect(() => {
    if (props.client !== undefined) {
      const client = props.client;
      setClientDetails({
        Id: client.Id,
        Name: client.Name,
        MaxUsers: client.MaxUsers,
        Type: client.Type,
        StartDate: moment(client.StartDate).format("YYYY-MM-DD"),
        ActiveUntil: client.ActiveUntil
          ? moment(client.ActiveUntil).format("YYYY-MM-DD")
          : null,
      });
      setClientUser({
        Id: client.Users[0].Id,
        Email: client.Users[0].Email,
        IdNumber: client.Users[0].IdNumber,
        Password: "",
        Firstname: client.Users[0].Firstname,
        Lastname: client.Users[0].Lastname,
        Telephone: client.Users[0].Telephone,
      });
    }
  }, [props.client]);

  return client.Users ? (
    <Modal {...props} title="Edit Client">
      {/* <p>Client Details</p> */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          doEditClient();
        }}
      >
        <Input
          required
          autoFocus
          placeholder="Client Name"
          value={clientDetails.Name}
          onChange={(e) =>
            setClientDetails({
              ...clientDetails,
              Name: e.target.value,
            })
          }
        />
        {isAdmin && (
          <>
            <Input
              required
              type="number"
              placeholder="Max Users"
              value={clientDetails.MaxUsers}
              onChange={(e) =>
                setClientDetails({
                  ...clientDetails,
                  MaxUsers: e.target.value,
                })
              }
              min={1}
            />
            <Select
              placeholder="Client Type"
              value={clientDetails.Type}
              style={{ width: "100%" }}
              onChange={(e) => {
                if (e.target.value == 1) {
                  setClientDetails({
                    ...clientDetails,
                    Type: e.target.value,
                    IsTrial: true,
                  });
                } else {
                  setClientDetails({
                    ...clientDetails,
                    Type: e.target.value,
                    IsTrial: false,
                  });
                }
              }}
            >
              <optgroup label="Clients">
                <option value="1">Trial</option>
                <option value="2">Paying</option>
              </optgroup>
              <optgroup label="Other">
                <option value="3">Demo</option>
                <option value="4">Testing</option>
              </optgroup>
            </Select>
            <div style={{ padding: "10px" }}>
              <span>Start date:</span>
              <Input
                type="date"
                value={clientDetails.StartDate}
                style={{ width: "100%" }}
                onChange={(e) =>
                  setClientDetails({
                    ...clientDetails,
                    StartDate: e.target.value,
                  })
                }
              />
            </div>
            <div style={{ padding: "10px" }}>
              <span>End date:</span>
              <Input
                type="date"
                value={clientDetails.ActiveUntil}
                style={{ width: "100%" }}
                onChange={(e) =>
                  setClientDetails({
                    ...clientDetails,
                    ActiveUntil: e.target.value,
                  })
                }
              />
            </div>
          </>
        )}

        <Button
          submit
          // submit onClick={() => doEditClient()}
        >
          Submit
        </Button>
      </form>
    </Modal>
  ) : null;

  function doEditClient() {
    // Check Fields
    // if (clientDetails.Name.replace(/ /g, "") === "") {
    //   alert("Please enter Client Name");
    // } else if (clientDetails.MaxUsers.toString().replace(/ /g, "") === "") {
    //   alert("Please enter Max Users");
    // } else if (parseInt(clientDetails.MaxUsers) <= 0) {
    //   alert("Max users number must be positive");
    // }
    // else if (clientUser.Firstname.replace(/ /g, "") === "") {
    //   alert("Please enter First Name");
    // } else if (clientUser.Lastname.replace(/ /g, "") === "") {
    //   alert("Please enter Last Name");
    // } else if (clientUser.Email.replace(/ /g, "") === "") {
    //   alert("Please enter Email");
    // } else if (clientUser.Password.replace(/ /g, "") === "") {
    //   alert("Please enter Password");
    // } else if (clientUser.Telephone.replace(/ /g, "") === "") {
    //   alert("Please enter Telephone");
    // }
    dispatch(showLoader());
    dispatch(
      editClient({
        Client: clientDetails,
        // Users: [clientUser]
      })
    ).then((res) => {
      if (res.payload.Success) {
        resetClientModal();
        props.close();
        dispatch(showLoader());
        dispatch(getClient(clientDetails.Id)).then(() =>
          dispatch(hideLoader())
        );
      } else {
        dispatch(hideLoader());
        // alert("Error editing client");
        alert(t(res.payload.ErrorCode));
      }
    });
  }

  function resetClientModal() {
    setClientDetails(initialClientDetails);
    setClientUser(initialClientUser);
  }
}

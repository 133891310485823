import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../../Components/Title/title";
import Box from "../../../Components/Box/box";
import "./admin-home-page.scss";
import {
  getClientsStats,
  getNewClientsByDate,
} from "../../../Redux/clients-slice";
import { hideLoader, showLoader } from "../../../Redux/loader-slice";
import { useTranslation } from "react-i18next";
import Select from "../../../Components/Select/select";
import { useHistory } from "react-router";

export default function AdminHomePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.clientsReducer.stats);
  const newClients = useSelector((state) => state.clientsReducer.newClients);

  const [newClientsWeeksAgo, setNewClientsTimeAgo] = useState(1);
  const [selectedWeeksAgo, setSelectedWeeksAgo] = useState(1);

  // i18next
  const { t, i18n } = useTranslation("Errors");

  useEffect(() => {
    dispatch(showLoader());
    dispatch(getClientsStats())
      .then((res) => {
        if (res.payload.Success) {
          dispatch(hideLoader());
          // console.log(stats);
        } else {
          dispatch(hideLoader());
          alert(t(res.payload.ErrorCode));
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    dispatch(getNewClientsByDate(selectedWeeksAgo))
      .then((res) => {
        if (res.payload.Success) {
        } else {
          alert(t(res.payload.ErrorCode));
        }
      })
      .catch(() => {});
  }, [selectedWeeksAgo]);

  return (
    <div className="admin-home-page">
      <Title>Home Page</Title>
      <Box width={400}>
        <h3>Statistics - Paying & Active</h3>
        <br />
        <ul>
          <li>
            Total Clients: <strong>{stats.TotalClients}</strong>
          </li>
          <li>
            Paying Clients: <strong>{stats.TotalPayingClients}</strong>
          </li>
          <li>
            Trial Clients: <strong>{stats.TotalTrialClients}</strong>
          </li>
          <li>
            Total Environments: <strong>{stats.TotalEnvironments}</strong>
          </li>
          <li>
            Total Users: <strong>{stats.TotalUsers}</strong>
          </li>
        </ul>
        <br />
      </Box>
      <Box width={"100%"}>
        <h3 style={{ display: "inline-block" }}>New Clients</h3>
        <Select
          placeholder="Weeks ago..."
          value={selectedWeeksAgo}
          style={{ float: "right" }}
          onChange={(e) => setSelectedWeeksAgo(e.target.value)}
        >
          <option value="1">1 Week</option>
          <option value="2">2 Weeks</option>
          <option value="4">1 Month</option>
        </Select>
        <br /> <br />
        <div className="new-clients-container">
          {newClients.length > 0
            ? newClients.map((newClient) => {
                return (
                  <div
                    onClick={() =>
                      history.push("/admin/manage-clients/" + newClient.Id)
                    }
                  >
                    <Box>
                      <h3>
                        {newClient.Name}
                        {newClient.IsTrial && (
                          <span style={{ color: "red" }}> (Free Trial)</span>
                        )}
                      </h3>
                      <p>
                        Joined:{" "}
                        {new Date(newClient.CreatedOn).toLocaleDateString()}
                      </p>
                    </Box>
                  </div>
                );
              })
            : "No Results"}
        </div>
      </Box>

      <Box width={"100%"}>
        <h3>Expires This Week</h3>
        <br />
        <div className="new-clients-container">
          {stats.WeekExpiresClients && stats.WeekExpiresClients.length > 0
            ? stats.WeekExpiresClients.map((client) => {
                return (
                  <div
                    style={{ display: "inline-block" }}
                    onClick={() =>
                      history.push("/admin/manage-clients/" + client.Id)
                    }
                  >
                    <Box>
                      <h3>
                        {client.Name}
                        {client.IsTrial && (
                          <span style={{ color: "red" }}> (Free Trial)</span>
                        )}
                      </h3>
                      <p>{client.DaysLeft} Days left</p>
                    </Box>
                  </div>
                );
              })
            : "No Results"}{" "}
        </div>
      </Box>
    </div>
  );
}

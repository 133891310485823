import React from "react";
import Modal from "../../../../../Components/Modal/modal";
import { useState } from "react";
import CheckBox from "../../../../../Components/CheckBox/checkbox";
import Select from "../../../../../Components/Select/select";
import Button from "../../../../../Components/Button/button";
import Api from "../../../../../Utils/api";
import "./update-daily-job-modal.scss";

export default function UpdateDailyJobModal(props) {
  const [showDaysList, setShowDaysList] = useState(
    props.dayOfDailyJob != "" ? true : false
  );
  const [selectedDate, setSelectedDate] = useState(
    showDaysList ? props.dayOfDailyJob : "4"
  );
  return (
    <div className="update-daily-job-modal-container">
      <Modal
        title="Update daily Job Notification"
        environment={props.environment}
        clientId={props.clientId}
        isOpen={props.isOpen}
        close={() => {
          props.close();
          setShowDaysList(props.dayOfDailyJob != "" ? true : false);
          setSelectedDate(
            props.dayOfDailyJob != "" ? props.dayOfDailyJob : "4"
          );
        }}
      >
        <div className="send-daily-job-checkbox">
          <CheckBox
            disabled={false}
            checked={showDaysList}
            onClick={(e) => setShowDaysList(!showDaysList)}
          />
          <span
            className="send-daily-job"
            onClick={() => setShowDaysList(!showDaysList)}
          >
            Send daily job
          </span>
        </div>
        <br />
        {showDaysList ? (
          <div>
            <Select
              placeholder="Day of daily job"
              value={selectedDate}
              // style={{ float: "left" }}
              onChange={(e) => setSelectedDate(e.target.value)}
            >
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </Select>
          </div>
        ) : (
          ""
        )}
        <div className="confirm-buttons">
          <Button
            style={{ display: "inline-block", margin: "0 10px" }}
            onClick={() => {
              Api.post("/Environment/UpdateDailyJobDate", {
                LicenceId: props.environment.Id,
                IsActive: showDaysList ? "true" : "false",
                DayNumber: selectedDate,
              });
              props.close();
              setShowDaysList(showDaysList);
              setSelectedDate(showDaysList ? selectedDate : "4");
            }}
          >
            Confirm
          </Button>
          <Button
            style={{ display: "inline-block" }}
            onClick={() => {
              props.close();
              setShowDaysList(props.dayOfDailyJob != "" ? true : false);
              setSelectedDate(
                props.dayOfDailyJob != "" ? props.dayOfDailyJob : "4"
              );
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
}

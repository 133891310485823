import React from "react";
// Functions
import "./toggle-client-modal.scss";
import { useDispatch } from "react-redux";
// Components
import Button from "../../../../../Components/Button/button";
import Modal from "../../../../../Components/Modal/modal";
import {
  toggleClientActivation,
  getClient,
} from "../../../../../Redux/clients-slice";
import { hideLoader, showLoader } from "../../../../../Redux/loader-slice";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function ToggleClientModal(props) {
  const dispatch = useDispatch();
  // i18next
  const { t, i18n } = useTranslation("Errors");

  return (
    <div>
      <Modal
        title={props.client.Active ? "Turn off Client" : "Turn on Client"}
        {...props}
      >
        <div style={{ textAlign: "center" }}>
          {props.client.Active ? (
            <span>
              {`Are you sure you want to turn off the Client '${props.client.Name}'?`}
            </span>
          ) : (
            <span>
              {`Are you sure you want to turn on the Client '${props.client.Name}'?`}
            </span>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          {/* {props.client.ActiveUntil} */}
          <Button
            style={{ display: "inline-block", margin: "0 10px" }}
            onClick={doToggleClient}
          >
            {props.client.Active ? "Turn off" : "Turn on"}
          </Button>
          <Button
            style={{ display: "inline-block" }}
            onClick={() => props.close()}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
  function doToggleClient() {
    dispatch(showLoader());
    dispatch(toggleClientActivation(props.client.Id)).then((res) => {
      if (res.payload.Success) {
        dispatch(hideLoader());
        dispatch(getClient(props.client.Id));
        props.close();
      } else {
        dispatch(hideLoader());
        // alert("Error toggling environment");
        alert(t(res.payload.ErrorCode));
      }
    });
  }
}
